export const createBreakPoints = function(
  labels,
  breakpoints,
  mediaString = false
) {
  let breakpointObject = {
    above: {},
    below: {},
    only: {}
  };
  labels.forEach((label, index) => {
    breakpointObject['above'][label] =
      (mediaString ? `@media` : ``) + `(min-width: ${breakpoints[index]})`;
    breakpointObject['below'][label] =
      (mediaString ? `@media` : ``) + `(max-width: ${breakpoints[index + 1]})`;
    breakpointObject['only'][label] =
      (mediaString ? `@media` : ``) +
      `(min-width: ${breakpoints[index]}) and (max-width: ${
        breakpoints[index + 1]
      })`;
  });
  return breakpointObject;
};
