import React, { Component, Fragment } from 'react';
import { Query } from 'react-apollo';
import ParseJson from './ParseJson';
import { Link } from 'react-router-dom';
import SiteContentQuery from './SiteContentQuery.gql';

class SiteContent extends Component {
  renderLink = item => {
    if (!item || item.length === 0) return false;

    let linkText = item[0];

    if (item.length > 1) {
      if (item[1].search('http') !== -1)
        return (
          <a href={item[1]} target="_blank" rel="noreferrer">
            {linkText}
          </a>
        );
      else return <Link to={item[1]}>{linkText}</Link>;
    } else {
      return linkText;
    }
  };
  render() {
    const { children, names } = this.props;
    return (
      <Fragment>
        <Query query={SiteContentQuery} variables={{ id: 174 }}>
          {result => {
            let list = [];
            if (result.data) {
              let { startPage } = result && result.data;
              if (startPage) {
                let filtered = startPage.items.filter(item =>
                  names.includes(item.item.name)
                );
                if (filtered.length > 0) {
                  filtered.forEach(item => {
                    const json = ParseJson(item.item.content);
                    const new_item = {
                      ...item.item,
                      content: json.isJson && json.content,
                      contentHtml: !json.isJson && item.item.content
                    };
                    list.push(new_item);
                  });
                }
              }
            }
            return children(list, this.renderLink);
          }}
        </Query>
      </Fragment>
    );
  }
}

export default SiteContent;
