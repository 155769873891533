import DynamicRoute from '@jetshop/core/components/DynamicRoute';
import TypekitFont from '@jetshop/core/components/Fonts/TypekitFont';
import PaginationProvider from '@jetshop/core/components/Pagination/PaginationProvider';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import GenericError from '@jetshop/ui/ErrorBoundary/Generic';
import LoadingBar from '@jetshop/ui/Loading/LoadingBar';
import ModalProvider from '@jetshop/ui/Modal/ModalProvider';
import ModalRoot from '@jetshop/ui/Modal/ModalRoot';
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';
import { Route, Switch } from 'react-router-dom';
import CategoryPreviewQuery from './CategoryPreviewQuery.gql';
import Container from './Layout/Container';
import Content from './Layout/Content';
import Footer from './Layout/Footer/Footer';
import Header from './Layout/Header/Header';
import LoadingPage from './LoadingPage';
import ProductPreviewQuery from './ProductPreviewQuery.gql';
import PagePreviewQuery from './PagePreviewQuery.gql';
import routeQuery from './RouteQuery.gql';
import './globalStyles';
import LogInPage from './Auth/LogInPage.loadable';
import SignUpPage from './Auth/Signup/SignUpPage.loadable';
import ForgotPassword from './Auth/ForgotPassword.loadable';
import ResetPassword from './Auth/ResetPassword.loadable';
import MyPages from './MyPages/MyPages.loadable';
import FindifyProvider from './Findify/FindifyProvider';
import ProductPage from './ProductPage/ProductPage.loadable';
import CategoryPage from './CategoryPage/CategoryPage.loadable';
import ContentPage from './ContentPage/ContentPage.loadable';
import SearchPage from './SearchPage/SearchPage.loadable';

const NotFound = loadable(() => import('./NotFoundPage'), {
  fallback: LoadingPage,
});

const PreviewRoute = loadable(
  () => import('@jetshop/core/components/DynamicRoute/PreviewRoute'),
  {
    fallback: LoadingPage,
  }
);

const LoadableStartPage = loadable(() => import('./StartPage/StartPage'), {
  fallback: LoadingPage,
});

const LogOutPage = loadable(
  () => import('@jetshop/core/components/AuthContext/LogOut'),
  {
    fallback: <LoadingPage />,
  }
);

function Shop() {
  const { routes } = useShopConfig();
  return (
    <GenericError>
      <ModalProvider>
        <Container>
          <LoadingBar />
          <TypekitFont
            primaryFont="mr-eaves-modern"
            secondaryFonts={['bebas-neue']}
            id="woo3mus"
          />
          <Helmet titleTemplate="%s" defaultTitle="Olssonsfiske" />
          <FindifyProvider>
            <Header />
            <Content>
              <PaginationProvider defaultProductsPerPage={24}>
                <Switch>
                  <Route exact path="/" component={LoadableStartPage} />
                  <Route path={routes.search.path} component={SearchPage} />
                  <Route path={routes.signup.path}>
                    <SignUpPage />
                  </Route>
                  <Route path={routes.login.path}>
                    <LogInPage />
                  </Route>
                  <Route path={routes.logout.path}>
                    <LogOutPage />
                  </Route>
                  <Route path={routes.myPages.path}>
                    <MyPages />
                  </Route>
                  <Route exact path={routes.forgotPassword.path}>
                    <ForgotPassword />
                  </Route>
                  <Route path={`${routes.resetPassword.path}/:token`}>
                    <ResetPassword />
                  </Route>
                  <Route
                    path="/preview"
                    render={(props) => (
                      <PreviewRoute
                        productPage={ProductPage}
                        categoryPage={CategoryPage}
                        contentPage={ContentPage}
                        productQuery={ProductPreviewQuery}
                        pageQuery={PagePreviewQuery}
                        categoryQuery={CategoryPreviewQuery}
                        StartPage={LoadableStartPage}
                        {...props}
                      />
                    )}
                  />
                  <DynamicRoute
                    routeQuery={routeQuery}
                    productPage={ProductPage}
                    categoryPage={CategoryPage}
                    contentPage={ContentPage}
                    notFoundPage={NotFound}
                    LoadingPage={LoadingPage}
                  />
                </Switch>
              </PaginationProvider>
            </Content>
            <Footer />
          </FindifyProvider>
          <ModalRoot />
          <ScrollRestorationHandler
            ignoreForRouteTypes={[
              'sortOrderChange',
              'filterChange',
              'FindifyUpdate',
            ]}
          />
        </Container>
      </ModalProvider>
    </GenericError>
  );
}

export default Shop;
