import * as translations from '../translations';
import channelsQuery from './ChannelsQuery.gql';
import homeCategoriesQuery from './components/Layout/Header/Categories/HomeCategoriesQuery.gql';
import siteContentQuery from './components/Util/SiteContentQuery.gql';
import routeQuery from './components/RouteQuery.gql';
import {
  LoadableStandardCategoryPage,
  LoadableWindowedCategoryPage,
} from './components/CategoryPage/CategoryPage';
import { theme } from './Theme';
import createFacebookTracker from '@jetshop/core/server/tracking/facebook';
import ProductPage from './components/ProductPage/ProductPage.loadable';
import ContentPage from './components/ContentPage/ContentPage.loadable';

const config = {
  theme,
  preload: {
    routeQuery,
    preloadComponents: {
      Product: [ProductPage],
      Category: [LoadableStandardCategoryPage, LoadableWindowedCategoryPage],
      Page: [ContentPage],
    },
  },
  apolloConfig: {
    shopid: process.env.REACT_APP_SHOP_ID || 'olssonsfiske',
    graphQLURI:
      process.env.REACT_APP_GRAPHQL_URI || 'https://storeapi.jetshop.se',
    token:
      process.env.REACT_APP_APOLLO_TOKEN ||
      '359fd7c1-8e72-4270-b899-2bda9ae6ef57',
    engineApiKey: process.env.ENGINE_API_KEY || '',
    enableGateway: false,
    channelsQuery,
    persistedQueries: [
      {
        query: homeCategoriesQuery,
        variables: { levels: 1 },
      },
      {
        query: siteContentQuery,
        variables: { id: 174 },
      },
    ],
  },
  trackingID: process.env.REACT_APP_GA_TRACKING_ID || 'UA-123334166-1',
  tagManagerID: process.env.REACT_APP_GTM_CONTAINER_ID || 'GTM-NQ8WS3',
  relewareEnabled: true,
  intl: {
    translations,
    defaultLocale: 'en',
    options: {},
  },
  channelOverrides: {},
  disableGeoRedirect: true,
  singleDomainMode: false,
  schemaExtensions: [],
  preserveRedirect: true,
  structuredData: {
    disableDefaultProductData: false,
  },
  trackers: [],
  serverTrackers: [
    createFacebookTracker({
      pixelID: process.env.FACEBOOK_PIXEL_ID,
      token: process.env.FACEBOOK_CAPI_TOKEN,
    }),
  ],
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  loginPath: '/login',
  pathsWithNoAuthRequired: ['/login', '/reset-password', '/forgot-password'],
};

export default config;
