import React from 'react';
import { styled } from 'linaria/react';
import SubscribeToNewsletter from '@jetshop/core/components/Mutation/SubscribeToNewsletter';
import t, { Intl } from '@jetshop/intl';
import { theme } from '../Theme';

const Wrapper = styled('div')`
  margin: 1rem auto 0.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  form{
    display: flex;
  }
  button {
    width: 30%;
    height: 46px;
    line-height: 46px;
    cursor: pointer;
    background: ${theme.colors.buy};
    border: 1px solid white;
    color: ${theme.button.color};
    font-size: ${theme.button.fontSize};
    transition: all 0.1s ease-in;
    :hover {
    }
  }
  input {
    border: none; //1px solid ${theme.colors.buy};
    height: 46px;
    line-height: 46px;
    width: 70%;
    padding-left: 20px;
    background: #fff;
    color: ${theme.colors.font};
    ::placeholder {
      color: ${theme.colors.font};
    }
    &:focus {
      ::placeholder {
      }
    }
  }
`;

const Error = styled('div')`
  margin-bottom: 0.5rem;
  color: #650e1b;
`;

// Prevent the normal form submission behaviour, then call the
// onSubmit provided by the mutation
const submitEmail = (email, onSubmit) => e => {
  e.preventDefault();
  onSubmit(email);
};

class NewsletterField extends React.Component {
  state = {
    email: '',
    completed: false,
    error: false
  };

  // State change methods
  updateEmail = e =>
    this.setState({ email: e.currentTarget.value, error: false });
  setCompleted = () => this.setState({ completed: true });
  setError = () => this.setState({ error: true });
  unsetError = () => this.setState({ error: false });

  render() {
    return (
      <SubscribeToNewsletter
        onCompleted={this.setCompleted}
        onError={this.setError}
      >
        {(subscribe, result) => (
          <React.Fragment>
            <Wrapper>
              {/* Show the completion success message once signed up, otherwise show the form */}
              {this.state.completed ? (
                <p>{t('You are now subscribed')}</p>
              ) : (
                <form onSubmit={submitEmail(this.state.email, subscribe)}>
                  <Intl>
                    {/* This is necessary to use t for the placeholder string */}
                    {t => (
                      <input
                        type="email"
                        placeholder={t('Enter email address')}
                        onChange={this.updateEmail}
                      />
                    )}
                  </Intl>
                  <button
                    style={this.state.email === '' ? { opacity: 0.5 } : null}
                  >
                    {/* Display 'Submitting…' while the form is posting */}
                    {result.loading ? t('Submitting…') : t('Submit')}
                  </button>
                </form>
              )}
            </Wrapper>
            {this.state.error && (
              <Error>
                {t(
                  'Something went wrong. Please check your email and try again.'
                )}
              </Error>
            )}
          </React.Fragment>
        )}
      </SubscribeToNewsletter>
    );
  }
}

export default NewsletterField;
