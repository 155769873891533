import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

const badgeMargin = '0';
const badgeMarginProductPage = '5px';

const BadgeWrapper = styled.div``;

const BadgeList = styled.div`
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  &.top-left {
    left: ${badgeMargin};
    top: ${badgeMargin};
  }
  &.top-right {
    right: ${badgeMargin};
    top: ${badgeMargin};
  }
  &.bottom-left {
    left: ${badgeMargin};
    bottom: ${badgeMargin};
  }
  &.bottom-right {
    right: ${badgeMargin};
    bottom: ${badgeMargin};
  }
  &[data-product-page='true'] {
    &.top-left {
      left: ${badgeMarginProductPage};
      top: ${badgeMarginProductPage};
    }
    &.top-right {
      right: ${badgeMarginProductPage};
      top: ${badgeMarginProductPage};
    }
    &.bottom-left {
      left: ${badgeMarginProductPage};
      bottom: ${badgeMarginProductPage};
    }
    &.bottom-right {
      right: ${badgeMarginProductPage};
      bottom: ${badgeMarginProductPage};
    }
  }
  .badge {
    margin-bottom: 4px;
    &:not(:last-child) {
      margin-right: 4px;
    }
    > img {
      max-width: 200px;
      max-height: 200px;
    }
    &.text {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 7px 10px;
      border-radius: 5px;
      font-weight: ${theme.fontWeights.semibold};
    }
  }
`;

const BadgeGroup = ({ badges, className = '', productPage }) => {
  if (!badges || badges.length === 0) return null;

  return (
    <BadgeList
      className={`badge-group ${className} `}
      data-product-page={productPage}
    >
      {badges.map((badge, index) => {
        const colors = badge.style ? badge.style.split(';') : [];
        const color = colors[0];
        const background = colors.length > 1 ? colors[1] : '';
        return (
          <div
            key={index}
            className={`badge ${badge.url ? 'image' : 'text'}`}
            style={{ color: color, background: background }}
          >
            {badge.url ? (
              <img src={badge.url} alt={badge.name} />
            ) : (
              <div className="badge-text">{badge.text}</div>
            )}
          </div>
        );
      })}
    </BadgeList>
  );
};

const ProductBadges = ({ badges, productPage = false }) => {
  if (!badges || badges.length === 0) return null;

  const badgeArray = [
    {
      className: 'top-left',
      badges: badges.filter((badge) => badge.location === 'TOP_LEFT'),
    },
    {
      className: 'top-right',
      badges: badges.filter((badge) => badge.location === 'TOP_RIGHT'),
    },
    {
      className: 'bottom-left',
      badges: badges.filter((badge) => badge.location === 'BOTTOM_LEFT'),
    },
    {
      className: 'bottom-right',
      badges: badges.filter((badge) => badge.location === 'BOTTOM_RIGHT'),
    },
  ];
  return (
    <BadgeWrapper>
      {badgeArray.map((group, index) => (
        <BadgeGroup {...group} key={index} productPage={productPage} />
      ))}
    </BadgeWrapper>
  );
};

export default ProductBadges;
