import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import t from '@jetshop/intl';
import { Price } from '@jetshop/ui/Price';
import { theme } from '../Theme';
import React from 'react';
import { styled } from 'linaria/react';
import ButtonWithLoading from '../ui/Button';
import CartItem from './CartItem';
import CloseButton from '../ui/CloseButton';
import DrawerHeader from '../ui/DrawerHeader';
import FreeShipping from './FreeShipping';
import SiteContent from '../Util/SiteContent';
import FindifyRecommendation from '../Findify/Recommendation';
import { useState } from 'react';
import { useEffect } from 'react';

const Wrapper = styled('div')`
  background: white;
  color: ${theme.colors.black};
  &.flyout {
    position: absolute;
    z-index: 999;
    background: white;
    right: 0;
    color: ${theme.colors.font};
    width: 420px;
    min-width: 19rem;
    max-width: 100%;
    border: 1px solid ${theme.colors.border};
  }
`;

const StyledCloseButton = styled(CloseButton)`
  padding: 10px;
  margin: -10px;
  position: absolute;
  right: 3px;
  top: 30px;
`;

const StyledDrawerHeader = styled(DrawerHeader)`
  background: ${theme.colors.white};
  margin: 0 1.5rem;
  padding: 0.5rem 0;
  align-items: flex-start;
  justify-content: flex-end;
  h2 {
    font-size: 1.4rem;
  }
`;
const Summary = styled('section')`
  background: #fff;
  padding: 1rem 1.5rem;

  a {
    width: 100%;
    color: white;
    :hover {
      color: white;
    }
  }
  .checkout-button {
    width: 100%;
    max-width: none;
  }
`;
const CartItems = styled('section')`
  padding: 1rem 1.5rem;
  ${theme.above.md} {
    //max-height: 360px;
    //overflow: auto;
  }
`;

const TotalSummary = styled('div')`
  font-size: 1.25rem;
  font-family: ${theme.font.families.heavy};
  color: ${theme.colors.font};
  display: flex;
  justify-content: space-between;
`;

const SummaryTitle = styled('h3')`
  text-transform: none;
  font-size: 1.2rem;
`;

const CartInfo = styled('div')`
  margin: 0.5rem 0;
  .red {
    color: ${theme.colors.red};
  }
`;

const Flex = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const ExtraProductsWrapper = styled('div')`
  padding: 1rem 1.5rem;
`;

const CartContent = ({ result, modal, ...rest }) => {
  const cart = result?.data?.cart;
  const items = result?.data?.cart?.items ?? [];
  const checkoutUrl = result?.data?.cart?.externalCheckoutUrl;
  const discounts = result?.data?.cart?.aggregatedDiscounts ?? [];
  const track = useTracker();
  const item_ids = items.map((item) =>
    item.variant ? item.variant.articleNumber : item.articleNumber
  );

  const valueLeft = 1000 - result.data?.cart?.productTotal?.incVat;
  const findifyValue = valueLeft < 0 ? 100 : valueLeft;

  const [count, setCount] = useState(cart.totalQuantity);
  const [showFind, setFind] = useState(true);

  useEffect(() => {
    if (count !== cart.totalQuantity) {
      setFind(false);
      setTimeout(() => {
        setFind(true);
      }, 1);
      setCount(cart.totalQuantity);
    }
  }, [cart.totalQuantity, count]);

  if (items.length === 0) {
    return (
      <Wrapper {...rest}>
        <StyledDrawerHeader>
          <h2>{t('No items in cart.')}</h2>
          <StyledCloseButton onClick={modal.hideTarget} />
        </StyledDrawerHeader>
      </Wrapper>
    );
  }

  return (
    <Wrapper {...rest}>
      <StyledDrawerHeader>
        <h2>{t('Your Cart')}</h2>
        <StyledCloseButton onClick={modal.hideTarget} />
      </StyledDrawerHeader>
      <Summary>
        {discounts.length > 0 && (
          <div className="discounts">
            <h4>{t('Cart total')}</h4>
            <Flex>
              <label>{t('Order value')}</label>
              <Price price={result.data.cart.productPreviousTotal} />
            </Flex>
            <h4>{t('Discounts')}</h4>
            {discounts.map((discount) => {
              return (
                <Flex key={discount.name}>
                  <label>{discount.name}</label>
                  <Price price={discount.value} css={{ display: 'inline' }} />
                </Flex>
              );
            })}
          </div>
        )}
        <TotalSummary>
          <SummaryTitle>{t('Total')}</SummaryTitle>
          <Price price={result.data.cart.productTotal} />
        </TotalSummary>
        <SiteContent names={['CartInfo']}>
          {(items) => {
            const item = items[0];
            if (item?.contentHtml)
              return (
                <CartInfo
                  dangerouslySetInnerHTML={{
                    __html: item.contentHtml,
                  }}
                />
              );
            return null;
          }}
        </SiteContent>
        <FreeShipping
          className="shipping"
          cartTotal={result.data.cart.productTotal}
        />
        <ButtonWithLoading className="buy checkout-button">
          <a
            href={checkoutUrl}
            onClick={(event) => {
              //event.preventDefault();
              track(
                trackCartCheckoutEvent({
                  cart: cart,
                  callback: () => {
                    window.location = checkoutUrl;
                  },
                })
              );
            }}
          >
            {t('Check out')}
          </a>
        </ButtonWithLoading>
      </Summary>
      <CartItems>
        {items.map((item) => (
          <CartItem item={item} key={item.id} close={modal.hideTarget} />
        ))}
      </CartItems>
      {showFind && (
        <ExtraProductsWrapper>
          <FindifyRecommendation
            id={'cart-findify-rec-3'}
            rules={[
              {
                action: 'exclude',
                type: 'text',
                name: 'id',
                values: [
                  {
                    value: [item_ids],
                  },
                ],
              },
              {
                action: 'include',
                type: 'range',
                name: 'price',
                values: [
                  {
                    from: findifyValue,
                    to: findifyValue * (findifyValue < 100 ? 3 : 1.5),
                  },
                ],
              },
            ]}
          />
        </ExtraProductsWrapper>
      )}
    </Wrapper>
  );
};

export default CartContent;
