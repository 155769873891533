import React from 'react';
import { styled } from 'linaria/react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { theme } from '../../../Theme';
import { Link } from 'react-router-dom';
import CloseButton from '../../../ui/CloseButton';
import MobileCategories from './Categories/MobileCategories';
import UIDrawer from '@jetshop/ui/Modal/Drawer/Drawer';
import DrawerHeader from '../../../ui/DrawerHeader';
import PartnerLogos from './PartnerLogos';
import Logo from '../Logo';

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: 17px;
  right: 10px;
  margin: -10px;
  padding: 10px;
`;

const Scroll = styled('div')`
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  flex: 1 1 auto;
`;

const MobileNavWrapper = styled('div')`
  height: 100%;
`;

const StyledDrawerHeader = styled(DrawerHeader)`
  background: #fff;
`;

const LogoWrapper = styled('div')`
  padding: 10px 0;
  max-width: 100%;
  width: 100px;
`;

const SiteList = styled('div')`
  margin-top: 2rem;
  padding: 0 1rem;
`;

const SiteLinks = styled(Link)`
  display: block;
  line-height: 36px;
  font-size: 14px;
  text-decoration: none;
  //padding: 0 1rem;
  border-bottom: 1px solid ${theme.colors.border};
`;

const pageQuery = gql`
  query SitePagesQuery {
    pages {
      name
      id
      primaryRoute {
        path
      }
    }
  }
`;

const MobileNav = ({
  categories,
  categoryTreeRoots,
  hideTarget,
  isOpen,
  right,
  ...rest
}) => (
  <UIDrawer isOpen={isOpen} right={right} size={360}>
    <MobileNavWrapper>
      <StyledDrawerHeader>
        <StyledCloseButton onClick={hideTarget} />
        <LogoWrapper>
          <Logo mobile={true} />
        </LogoWrapper>
      </StyledDrawerHeader>
      <Scroll>
        <MobileCategories
          hideTarget={hideTarget}
          categories={categoryTreeRoots}
          categoryTreeRoots={categoryTreeRoots}
        />
        <SiteList>
          <Query query={pageQuery}>
            {({ data }) =>
              data && data.pages
                ? data.pages.map(page => (
                    <React.Fragment key={page.id}>
                      {page.primaryRoute && (
                        <SiteLinks
                          to={page.primaryRoute.path}
                          onClick={hideTarget}
                        >
                          {page.name}
                        </SiteLinks>
                      )}
                    </React.Fragment>
                  ))
                : null
            }
          </Query>
        </SiteList>
        <PartnerLogos />
      </Scroll>
    </MobileNavWrapper>
  </UIDrawer>
);
export default MobileNav;
