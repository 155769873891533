import React, { useState } from 'react';
import { styled } from 'linaria/react';
import CategoryLink from '@jetshop/ui/CategoryLink';
import SubMenu from './SubMenu';
import { theme } from '../../../Theme';

const DesktopNavWrapper = styled('div')`
  height: ${theme.desktopHeader.nav.height.lg + 'px'};
  transition: height 0.8s ease;
  width: 100%;
  border-bottom: 1px solid ${theme.colors.border};
  position: relative;
  z-index: -1;
  ${theme.only.md} {
    height: ${theme.desktopHeader.nav.height.md + 'px'};
  }
  &[data-scrolling='true'] {
    height: ${theme.desktopHeader.nav.height.scroll + 'px'};
  }
`;

const List = styled('ul')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;

  > li {
    list-style: none;
    padding: 0 ${theme.desktopHeader.nav.padding};
    @media (max-width: 1560px) {
      padding: 0 0.8rem;
    }
    @media (max-width: 1350px) {
      padding: 0 0.6rem;
    }
    @media (max-width: 1260px) {
      padding: 0 0.5rem;
    }
    > a {
      position: relative;
      height: 100%;
      display: block;
      font-family: ${theme.font.families.heavy};
      font-weight: ${theme.font.weights.normal};
      letter-spacing: ${theme.font.header.letterSpacing};
      text-transform: uppercase;
      font-size: ${theme.desktopHeader.nav.fontSize};
      line-height: ${theme.desktopHeader.nav.height.lg + 'px'};
      ${theme.only.md} {
        line-height: ${theme.desktopHeader.nav.height.md + 'px'};
      }
      @media (max-width: 1310px) {
        font-size: 1rem;
      }
      @media (max-width: 1220px) {
        font-size: 0.9rem;
      }
    }
    a.active {
      text-decoration: underline;
    }
    a.selected {
      text-decoration: underline;
    }
    &.sub-menu-open {
      .sub-menu-wrapper {
        display: block;
      }
    }
  }
  &[data-scrolling='true'] {
    > li {
      > a {
        line-height: ${theme.desktopHeader.nav.height.scroll + 'px'};
      }
    }
  }
`;

const SubMenuIcon = styled('span')`
  position: absolute;
  bottom: 0;
  left: 25%;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid black;
  &[data-scrolling='true'] {
    bottom: 0;
  }
`;

function handleCatOpen(e, category, setActiveCategory, setSubMenu) {
  if (category.hasSubcategories) {
    e.preventDefault();
    setActiveCategory(category);
    setSubMenu(true);
  } else setSubMenu(false);
}

function isActiveMenu(category, activeCategory, subMenuOpen) {
  if (subMenuOpen && activeCategory && activeCategory.id === category.id)
    return true;
}

const DesktopNav = ({ categoryTreeRoots, scrolling }) => {
  const [activeCategory, setActiveCategory] = useState(null);
  const [subMenuOpen, setSubMenu] = useState(false);
  return (
    <DesktopNavWrapper
      data-scrolling={scrolling}
      //onMouseLeave={() => setSubMenu(false)}
    >
      <List data-scrolling={scrolling}>
        {categoryTreeRoots.map((category, index) => (
          <li
            key={category.id}
            //onMouseEnter={e => this.handleCatOpen(e, category)}
            className={
              isActiveMenu(category, activeCategory, subMenuOpen)
                ? 'sub-menu-open'
                : ''
            }
          >
            <CategoryLink
              category={category}
              //onClick={() => setSubMenu(false)}
              onClick={e =>
                handleCatOpen(e, category, setActiveCategory, setSubMenu)
              }
            >
              {category.name}
              {isActiveMenu(category, activeCategory, subMenuOpen) && (
                <SubMenuIcon data-scrolling={scrolling} />
              )}
            </CategoryLink>
            {isActiveMenu(category, activeCategory, subMenuOpen) && (
              <SubMenu
                activeCategory={activeCategory}
                category={category}
                setActiveCategory={setActiveCategory}
                setSubMenu={setSubMenu}
                data-scrolling={scrolling}
                index={index}
              />
            )}
          </li>
        ))}
      </List>
    </DesktopNavWrapper>
  );
};

export default DesktopNav;
