import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/pro-light-svg-icons';
import { faRocket } from '@fortawesome/pro-light-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { faCreditCard } from '@fortawesome/pro-light-svg-icons';
import SiteContent from '../../../Util/SiteContent';
import { styled } from 'linaria/react';
import { theme } from '../../../Theme';

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
  margin-right: 10px;
  ${theme.below.md} {
    margin-right: 6px;
  }
`;

const ListItem = styled('li')`
  font-size: 12px;
  display: flex;
  align-items: center;
`;

function getIcon(index) {
  switch (index) {
    case 0:
      return faDollarSign;
    case 1:
      return faRocket;
    case 2:
      return faInfoCircle;
    case 3:
      return faCreditCard;
    default:
      return faInfoCircle;
  }
}

const TopBarUsp = () => {
  return (
    <SiteContent names={['TopBarUsp']}>
      {items => {
        const item = items[0];
        return (
          <Fragment>
            {item && item.content.list && (
              <ul className="top-bar-usp-list">
                {item.content.list &&
                  item.content.list.map((item, index) => (
                    <ListItem key={index}>
                      <StyledIcon icon={getIcon(index)} className="" />
                      <span
                        dangerouslySetInnerHTML={{
                          __html: item
                        }}
                      />
                    </ListItem>
                  ))}
              </ul>
            )}
          </Fragment>
        );
      }}
    </SiteContent>
  );
};

export default TopBarUsp;
