import { createBreakPoints } from './helpers';

/** 
    To define your own breakpoints, you can edit the breakpoint array below.
    The index of the breakpoint corresponds to the label. So, in this case, xs is at 20rem, sm is at 40rem, ect.
    You can add additional breakpoints or change the values of the ones that are currently there however it is important you keep the four existing labels as we use them for some components in @jetshop/ui 
. */

export const labels = ['xs', 'sm', 'md', 'lg', 'xl'];
const breakpoints = ['20rem', '40rem', '50rem', '64rem', '80rem'];

export const mediaQueries = createBreakPoints(labels, breakpoints);
const cssMediaQueries = createBreakPoints(labels, breakpoints, true);

const fontSizes = [
  '12px',
  '14px',
  '16px',
  '20px',
  '24px',
  '32px',
  '48px',
  '64px',
  '72px'
];
const space = [
  '0px',
  '8px',
  '16px',
  '24px',
  '32px',
  '40px',
  '48px',
  '56px',
  '64px'
];
const fontWeights = {
  light: 300,
  regular: 400,
  semibold: 700
};

export const fonts = {
  primary: "'mr-eaves-modern', sans-serif"
};

const font = {
  families: {
    body: "'mr-eaves-modern', sans-serif",
    heavy: "'bebas-neue', sans-serif"
  },
  weights: {
    light: 300,
    normal: 400,
    bold: 700
  },
  size: '16px',
  paragraph: {
    fontSize: '0.875rem',
    lineHeight: '1.7em',
    marginBottom: '1rem'
  },
  header: {
    textTransform: 'uppercase',
    fontSize: [
      '__',
      ['3rem', '2rem'],
      ['2.4rem', '1.8rem'],
      ['1.8rem', '1.4rem'],
      ['1.4rem', '1.2rem'],
      ['1rem', '1rem'],
      ['0.875rem', '0.875rem']
    ],
    reduction: '10%',
    letterSpacing: '1px'
  }
};

const colors = {
  white: '#FFF',
  black: '#111',
  red: '#E82A2A',
  green: '#69a24c',
  blue: '#003311',
  beige: 'seashell',
  font: '#222',
  primary: '#740019',
  primaryLight: '#BA0028',
  secondary: '#222',
  discount: '#b44e3c',
  link: '#ce3c8a',
  border: '#777',
  borderLight: '#F1F1F1',
  borderDark: '#333',
  borderInput: '#AAA',
  lightgrey: '#f6f6f6',
  mediumgrey: '#999',
  darkgrey: '#444',
  tablegrey: '#DDD',
  vanillaWhite: '#fbf8f4',
  grey: '#565558',
  error: '#ff3131',
  success: '#69a24c',
  greys: [
    '#F9F9F9',
    '#F1F1F1',
    '#D5D5D5',
    '#C4C4C4',
    '#9A9A9A',
    '#777777',
    '#3B3B3B',
    '#222'
  ]
};

colors.buy = colors.primary;
colors.inStock = '#339600';
colors.outOfStock = colors.red;

const general = {
  maxWidth: '1300px',
  pagePadding: {
    lg: '1rem',
    md: '1rem',
    sm: '1rem'
  },
  hallmarkBorder: '2px solid ' + colors.borderDark,
  radius: '3px'
};

const button = {
  background: colors.primary,
  backgroundSecondary: colors.secondary,
  color: colors.white,
  fontFamily: font.families.heavy,
  fontSize: '1.2rem',
  textTransform: 'uppercase',
  letterSpacing: '1.5px'
};

const topBar = {
  height: {
    lg: 30,
    md: 30,
    sm: 30,
    scroll: 0
  },
  color: colors.white,
  background: colors.primaryLight
};

const desktopHeader = {
  height: {
    lg: 70,
    md: 70,
    scroll: 70
  },
  search: {
    height: {
      lg: 30,
      md: 30
    }
  },
  logo: {
    height: {
      lg: 50,
      md: 50,
      scroll: 50
    }
  },
  nav: {
    fontSize: '1rem',
    padding: '1.5rem',
    height: {
      lg: 40,
      md: 40,
      sm: 40,
      scroll: 40
    },
    useSubMenuImage: true
  }
};

const mobileHeader = {
  height: {
    xs: 60,
    sm: 60
  },
  search: {
    height: {
      xs: 60,
      sm: 60
    }
  },
  logo: {
    height: 36
  }
};

const header = {
  height: {
    lg:
      topBar.height.lg + desktopHeader.height.lg + desktopHeader.nav.height.lg,
    md:
      topBar.height.md + desktopHeader.height.md + desktopHeader.nav.height.md,
    sm: topBar.height.md + mobileHeader.height.sm,
    xs: topBar.height.md + mobileHeader.height.xs,
    scroll: topBar.height.scroll + desktopHeader.height.scroll
  },
  scrollBreak: 50
};

const footer = {
  background: colors.white,
  color: colors.font
};

const productGrid = {
  imageAspect: '4:3'
};

const productPage = {
  imageAspect: '1:1'
};

export const theme = {
  ...cssMediaQueries,
  fontSizes,
  space,
  fontWeights,
  fonts,

  //CUSTOM
  font,
  colors,
  general,
  button,
  topBar,
  desktopHeader,
  mobileHeader,
  header,
  footer,
  productGrid,
  productPage
};

export const ContentPadding = `
  padding-top: 0.5rem;
  padding-bottom: 2rem;
  ${theme.above.md} {
    padding-top: 1rem;
    padding-bottom: 4rem;
  }
`;
