import React from 'react';
import { styled } from 'linaria/react';

const ContentWrapper = styled('main')`
  background: #fff;
  flex: 1 1 auto;
  //padding-top: 1rem;
`;

const MainBackground = styled('div')``;

const Content = ({ children }) => {
  return (
    <ContentWrapper>
      <MainBackground>{children}</MainBackground>
    </ContentWrapper>
  );
};

export default Content;
