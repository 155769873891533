import useFreeShippingCheck from '@jetshop/core/hooks/useFreeShippingCheck';
import t from '@jetshop/intl';
import React from 'react';
import { styled } from 'linaria/react';
import { Price } from '../Price';
import { theme } from '../Theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';

const FreeShippingWrapper = styled('div')`
  margin-bottom: 0.5rem;
  padding: 0.4rem 0.5rem 0.3rem;
  background: ${theme.colors.greys[1]};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.85rem;
  line-height: 1;
`;
const ShippingText = styled('span')`
  text-transform: uppercase;
  margin-left: 5px;
  &.has-met-limit {
    color: ${theme.colors.green};
  }
`;
const Check = styled(FontAwesomeIcon)`
  color: ${theme.colors.green};
  font-size: 1rem;
`;

function FreeShipping({ cartTotal, ...rest }) {
  const { hasMetLimit, untilLimit, freeShippingConfigured } =
    useFreeShippingCheck({
      cartTotal,
    });

  if (!freeShippingConfigured) return null;
  return (
    <FreeShippingWrapper>
      {hasMetLimit ? (
        <>
          <Check icon={faCheck} />
          <ShippingText className={hasMetLimit ? 'has-met-limit' : ''}>
            {t('Free shipping')}
          </ShippingText>
        </>
      ) : (
        <>
          <Price key="price" price={untilLimit} />
          <ShippingText hasMetLimit={hasMetLimit}>
            {t('left to free shipping')}
          </ShippingText>
        </>
      )}
    </FreeShippingWrapper>
  );
}

export default FreeShipping;
