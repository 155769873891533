import Image from '@jetshop/ui/Image';
import { transparentDataImg } from '@jetshop/ui/Image/Image';
import ProductLink from '@jetshop/ui/ProductLink';
import React from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import { Price } from '../Price';
import { theme } from '../Theme';
import AddToCart from '../Cart/AddToCart';
import Button from '../ui/Button';
import t from '@jetshop/intl';
import { useStockStatus } from '@jetshop/ui/Stock/StockStatusContainer';
import StockStatusIndicator from '../ProductPage/StockStatus/StockStatusIndicator';
import ProductBadges from './ProductBadges';

export const ProductCardInner = styled('div')`
  padding: 0.5rem;
  border: 1px solid ${theme.colors.borderLight};
  background-color: white;
  position: relative;
  line-height: 1;
  min-width: 0;
`;

export const Details = styled('div')`
  position: relative;
  text-align: center;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-end;
  padding: 1rem 0 0.5rem;
  > * {
    flex-grow: 0;
    width: 100%;
  }
`;

export const ImageWrapper = styled('div')`
  position: relative;
`;

export const BadgeWrapper = styled('div')`
  color: white;
  text-align: center;
  font-size: 0.8rem;
  background: &.grid {
    .badge-top-left,
    .badge-top-right {
      top: -0.5rem;
    }
  }
  > div {
    > div {
      > div:not(.with-badge) {
        background: black;
        padding: 5px 10px;
      }
    }
  }
`;

export const Name = styled('h3')`
  font-size: 1rem;
  margin: 0 0 0.7rem 0;
  align-self: stretch;
  flex-grow: 1;
  line-height: 1;
  padding: 0;
  //text-overflow: ellipsis;
  //white-space: nowrap;
  overflow: hidden;
  text-transform: none;
  font-family: ${theme.font.families.body};
  height: 32px;
`;
export const SubName = styled('h3')`
  font-size: 1rem;
  margin: 0 0 0.5rem 0;
  align-self: stretch;
  flex-grow: 1;
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${theme.below.md} {
    font-size: 0.9rem;
  }
`;
export const ListPrice = styled(Price)`
  font-family: ${theme.font.families.heavy};
  font-size: 1.5rem;
  display: flex;
  height: 40px;
  justify-content: center;
  flex-direction: column;
  ${theme.below.md} {
    font-size: 1.2rem;
  }
  .new-price {
    color: ${theme.colors.discount};
    margin-bottom: 0.3rem;
  }
  .old-price {
    font-size: 1rem;
    display: block;
    text-decoration: none;
    color: ${theme.colors.greys[5]};
    &:before {
      content: 'REK.';
      margin-right: 5px;
    }
  }
`;

const StockStatusWrapper = styled('div')`
  margin-top: 0.7rem;
  > p {
    margin: 0 !important;
    font-size: 0.7rem !important;
    justify-content: center;
    text-transform: uppercase;
  }
`;

const BuyButton = styled(Button)`
  height: 36px;
  font-size: 1.1rem;
`;

const InfoButton = styled(Button)`
  height: 36px;
  font-size: 1.1rem;
`;

const wrapperStyling = css`
  font-size: 1rem;
  position: relative;
  a {
    text-decoration: none;
    color: inherit;
    display: block;
    background: white;
  }
`;

export function ProductCard({
  product,
  className,
  imageAspect = '1:1',
  imageSizes = [1 / 2, 1 / 2, 1 / 3, 300],
  forwardRef,
  as = 'div',
  children,
  ...linkProps
}) {
  const hasImages = product.images && product.images.length > 0;
  const Tag = as;
  const stockStatus = useStockStatus(product);

  return (
    <Tag
      className="product-card"
      css={[wrapperStyling, className]}
      data-testid="product"
      data-product-card
      ref={forwardRef}
    >
      <ProductCardInner className="product-card-inner">
        <ProductLink product={product} {...linkProps}>
          {hasImages ? (
            <ImageWrapper>
              <Image
                sizes={imageSizes}
                aspect={imageAspect}
                alt={product.images[0].alt}
                src={product.images[0].url}
              ></Image>
              <ProductBadges badges={product.badges} />
            </ImageWrapper>
          ) : (
            <Image src={transparentDataImg} />
          )}

          <Details className="product-card-detail">
            <header>
              <SubName className="sub-name">
                {product.subName || '\u00A0'}
              </SubName>
              <Name>{product.name}</Name>
            </header>
            <ListPrice
              price={product.price}
              previousPrice={product.previousPrice}
            />
            <StockStatusWrapper>
              <StockStatusIndicator
                status={stockStatus.status}
                text={stockStatus.text}
              />
            </StockStatusWrapper>
          </Details>
        </ProductLink>
        {!product.hasVariants &&
        product.stockStatus &&
        product.stockStatus.buyable ? (
          <AddToCart product={product} quantity={1} selectedVariation={product}>
            {(add) => (
              <BuyButton
                onClick={() => {
                  add();
                }}
              >
                {t('Add to cart')}
              </BuyButton>
            )}
          </AddToCart>
        ) : (
          <ProductLink product={product} {...linkProps}>
            <InfoButton>{t('Read more')}</InfoButton>
          </ProductLink>
        )}
        {children}
      </ProductCardInner>
    </Tag>
  );
}

export default ProductCard;
