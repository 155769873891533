import { css } from 'linaria';
import '@jetshop/ui/Theme/sanitizeCss';
import { theme } from './Theme';

const styles = `
  html, body {
    height: 100%;
    font-family: '-apple-system',
        'BlinkMacSystemFont',
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        'Arial',
        'sans-serif'
  }
  html, body {
    height: 100%;
    font-family: ${theme.font.families.body};
    color: ${theme.colors.font};
    font-size: ${theme.font.size};
    -webkit-font-smoothing: antialiased;
    font-weight:${theme.font.weights.normal};
    background: ${theme.colors.white};
    line-height: 1.5 !important;
    letter-spacing: 0.02em !important; 
  }
  html, body, #root {
    min-height: 100%;
  }
  body {
    -webkit-font-smoothing: antialiased;
  }
  /* 
   * apply a natural box layout model to all elements, but allowing components
   * to change */
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  html, body, #root {
    min-height: 100%;
  }
  #root {
    display: flex;
    flex-direction: column;
  }
  .ReactModal__Overlay--after-open {
    overflow: scroll;
  }


  h1, h2, h3, h4, h5, h6 {
    font-family: ${theme.font.families.heavy};
    font-weight: ${theme.font.weights.normal};
    text-transform: ${theme.font.header.textTransform};
    letter-spacing: ${theme.font.header.letterSpacing};
    line-height: 1.5;
    margin: 0;
    &.body-font{
      font-family: ${theme.font.families.body};
      font-weight: ${theme.font.weights.normal};
    }
  } 

  h1 { font-size: ${theme.font.header.fontSize[1][0]}; }
  h2 { font-size: ${theme.font.header.fontSize[2][0]}; }
  h3 { font-size: ${theme.font.header.fontSize[3][0]}; }
  h4 { font-size: ${theme.font.header.fontSize[4][0]}; }
  h5 { font-size: ${theme.font.header.fontSize[5][0]}; }
  h6 { font-size: ${theme.font.header.fontSize[6][0]}; }

  ${theme.below.md} {
    h1 { font-size: ${theme.font.header.fontSize[1][1]}; }
    h2 { font-size: ${theme.font.header.fontSize[2][1]}; }
    h3 { font-size: ${theme.font.header.fontSize[3][1]}; }
    h4 { font-size: ${theme.font.header.fontSize[4][1]}; }
    h5 { font-size: ${theme.font.header.fontSize[5][1]}; }
    h6 { font-size: ${theme.font.header.fontSize[6][1]}; }
  }

  a, a:active{
    color: ${theme.colors.font};
    text-decoration: none;
  }
  a:hover{

  }

  .seo-box a{
    color: ${theme.colors.link};
  }

  p { 
    font-size: 1rem; 
    line-height: 1.6;
  }
  
  strong{
    font-weight: ${theme.font.weights.bold};
  }

  img, iframe{
    max-width: 100%;
  }

  [data-flight-image-loaded="false"]{
    >div{
      font-size: 0;
    }
  }

  button, a, input {
    :focus {
      outline: none;
    }
  }

  ol, ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  button {
    cursor: pointer;
    padding: 0px;
    border: none;
    font: inherit;
    background: transparent;
    font-family: ${theme.font.families.body};
    font-size: ${theme.font.size};
    font-weight:${theme.font.weights.normal};
    color: ${theme.colors.font};
  }

  .svg-inline--fa {
    height: 1em;
  }

  p, dd {
    margin: 0;
    padding: 0;
  }

  input {
    -webkit-appearance: none;
  }

  input, text-area, select {
    font-family: ${theme.font.families.body};
  }
  select{
    font-size: 1rem;yarn s
  }
  
  .content-area{
    h1, h2, h3, h4, h5, h6{
      margin: 1em 0 1.666em 0;
    }
    p{
      margin: 0 0 1em 0;
    }
    >*:first-child{
      margin-top: 0 !important;
    }
    &.small{
      h1, h2, h3, h4, h5, h6{
        margin: 0.5em 0 1em 0;
      }
    }
  }

`;

css`
  :global() {
    ${styles};
  }
`;
