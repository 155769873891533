import React, { useState } from 'react';
import useFindify from '@findify/react-bundle';
import { useHistory } from 'react-router';
import { styled } from 'linaria/react';
import { useIntl } from '@jetshop/intl';
import { theme } from '../../Theme';
import { ReactComponent as Cross } from '@jetshop/ui/svg/Cross.svg';
import SearchIcon from '../../ui/icons/SearchIcon';

const placeholderStyle = `      
    color: ${theme.colors.darkerGrey};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    opacity: 1;
`;

const SearchBarContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15rem;
  &.mobile {
    padding: 0 1rem;
  }
  div {
    position: relative;
    width: 100%;
  }

  input {
    border: 1px solid ${theme.colors.mediumgrey};
    outline: none !important;
    width: 100%;
    height: 2rem;
    padding: 0 0.5rem;
    font-size: 1rem;
    color: ${theme.colors.black};

    ::-webkit-search-decoration,
    ::-webkit-search-cancel-button,
    ::-webkit-search-results-button,
    ::-webkit-search-results-decoration {
      display: none;
    }

    ::-webkit-input-placeholder {
      ${placeholderStyle}
    }

    :-ms-input-placeholder {
      ${placeholderStyle}
    }

    ::placeholder {
      ${placeholderStyle}
    }
  }

  &:not(&.mobile) {
    input {
      :focus {
        + .search-icon {
          display: none;

          + .close-icon {
            opacity: 1;
            cursor: pointer;
          }
        }
      }
    }
    span {
      &.close-icon {
        z-index: 2;
        opacity: 0;
      }
    }
  }

  &.mobile {
    span {
      &.search-icon {
        right: 2rem;
      }
      &.close-icon {
        right: 0;
      }
    }
  }

  span {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    color: ${theme.colors.black};
    &.close-icon {
      svg {
        stroke: ${theme.colors.black};
      }
    }
  }
`;

const SearchBar = ({ close = () => {}, mobile = false }) => {
  const history = useHistory();
  const [container] = useFindify({ type: 'autocomplete', history });
  const [inputValue, setInputValue] = useState('');
  const t = useIntl();

  const resetSearchInput = () => {
    setInputValue('');
    if (typeof document !== 'undefined') {
      document.body.tabIndex = 0;
      document.body.focus();
    }
  };

  return (
    <SearchBarContainer
      className={`findify-search-bar ${mobile ? 'mobile' : ''}`}
    >
      <div>
        <input
          id="findify-search-input"
          ref={container}
          type="search"
          placeholder={t('Hi, what are you looking for?')}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key.match(/escape/i)) {
              resetSearchInput();
            }
          }}
        />
        <span className="search-icon">
          <SearchIcon />
        </span>
        <span
          className="close-icon"
          onClick={() => {
            resetSearchInput();
            close();
          }}
        >
          <Cross />
        </span>
      </div>
    </SearchBarContainer>
  );
};

export default SearchBar;
