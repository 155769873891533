import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { theme } from '../../../Theme';

const List = styled('ul')`
  position: absolute;
  top: 0;
  &.left {
    left: 0;
    > li:first-child {
      padding-left: 0 !important;
    }
    > li {
      padding: 0 1rem;
      ${theme.below.lg} {
        padding: 0 0.7rem;
      }
      ${theme.below.sm} {
        padding: 0 0.6rem;
      }
    }
  }
  &.right {
    right: 0;
    justify-content: flex-end;
    > li:last-child {
      padding-right: 0 !important;
    }
    > li {
      padding: 0 1rem;
      ${theme.below.lg} {
        padding: 0 0.9rem;
      }
      ${theme.below.sm} {
        padding: 0 0.8rem;
      }
    }
  }
  &.center {
    position: static;
  }
  margin: 0;
  padding: 0;
  display: flex;
`;

const ListItem = styled('li')`
  list-style: none;
  color: ${theme.colors.greys[5]};
  display: block;
`;

const TopNav = ({ children, ...rest }) => (
  <List
    {...rest}
    className={cx(
      'top-nav-list',
      rest.left && 'left',
      rest.right && 'right',
      rest.center && 'center'
    )}
  >
    {React.Children.map(children, item => (
      <ListItem fontSize={1}>{item}</ListItem>
    ))}
  </List>
);

export default TopNav;
