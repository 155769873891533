import React from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import { SearchWrapper } from '../Search';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer';
import CartButton from '../../../Cart/CartButton';
import { Inner, PagePadding, PagePaddingWrapper } from '../../MaxWidth';
import TopNav from '../Util/TopNav';
import t from '@jetshop/intl';
import Logo from '../Logo';
import SearchIcon from '../../../ui/icons/SearchIcon';
import MenuIcon from '../../../ui/icons/MenuIcon';
import CustomerIcon from '../../../ui/icons/CustomerIcon';
import { theme } from '../../../Theme';
import UserIcon from '../../../ui/icons/UserIcon';
import SearchBar from '../../../Findify/Search/SearchBar';

const MobileHeaderWrapper = styled('div')`
  background: white;
  height: ${theme.mobileHeader.height.xs + 'px'};
  border-bottom: 1px solid ${theme.colors.border};
  ${theme.only.sm} {
    height: ${theme.mobileHeader.height.sm + 'px'};
  }
  ul.top-nav-list {
    height: 100%;
    align-items: center;
    > li {
      padding: 0 0.5rem;
    }
    > li > a,
    > li > button {
    }
  }
  ${PagePaddingWrapper},
  ${Inner} {
    height: 100%;
  }
  ${Inner} {
    display: flex;
    align-items: center;
  }
  .header-icon {
    font-size: 20px;
  }
`;

const StoreLogo = styled('div')`
  text-align: center;
  text-decoration: none;
  color: inherit;
  width: 121px;
  margin: 0 auto 0;
  a {
    text-decoration: none;
    > img,
    > svg {
      max-width: 100%;
    }
  }
`;

const MenuBar = styled('div')`
  position: relative;
  height: ${theme.mobileHeader.height.xs + 'px'};
  ${theme.only.sm} {
    height: ${theme.mobileHeader.height.sm + 'px'};
  }
`;

const HeaderButton = styled('button')`
  display: flex;
`;

const HeaderLink = styled(Link)`
  display: flex;
`;

const HeaderLinkExt = styled('a')`
  display: flex;
`;

const SearchButtonToggle = styled(HeaderButton)``;

const SearchText = styled('span')`
  color: ${theme.colors.font};
  text-transform: uppercase;
  margin-right: 5px;
  display: none;
`;

const SearchFieldWrapper = styled('div')`
  width: 100%;
  position: fixed;
  left: 0;
  top: ${theme.topBar.height.md + 'px'};
  z-index: 1;
  background: #f9f9f9;
  height: ${theme.mobileHeader.height.xs + 'px'};
  &[data-scrolling='true'] {
    top: 0;
  }
  ${theme.only.sm} {
    height: ${theme.mobileHeader.height.sm + 'px'};
  }
  ${SearchWrapper} {
    height: 100%;
  }
  > div {
    width: 100%;
    height: 100%;
  }
  input {
    border: none;
    height: 100%;
    background: transparent;
  }
  .submit-search {
    top: 4px;
  }
  .close-search {
    display: block;
  }
`;

const MobileHeader = ({ setSearch, searchOpen, scrolling }) => {
  const focusSearchInput = () => {
    if (typeof document !== 'undefined') {
      document.getElementById('findify-search-input')?.focus();
    }
  };

  return (
    <MobileHeaderWrapper>
      <MenuBar>
        <PagePadding>
          <Inner>
            <TopNav left>
              <DrawerTrigger id="menuDrawer" coverStyles={{ zIndex: 101 }}>
                {({ showTarget, hideTarget, isOpen }) => (
                  <HeaderButton onClick={isOpen ? hideTarget : showTarget}>
                    <MenuIcon className="header-icon" />
                  </HeaderButton>
                )}
              </DrawerTrigger>
              {searchOpen ? (
                <SearchFieldWrapper data-scrolling={scrolling}>
                  <SearchBar mobile={true} close={() => setSearch(false)} />
                </SearchFieldWrapper>
              ) : (
                <SearchButtonToggle
                  href="#"
                  onClick={() => {
                    setSearch(true);
                    focusSearchInput();
                  }}
                >
                  <SearchIcon className="header-icon" />
                  <SearchText>{t('Search')}</SearchText>
                </SearchButtonToggle>
              )}
            </TopNav>
            <StoreLogo>
              <Link to="/">
                <Logo mobile={true} />
              </Link>
            </StoreLogo>
            <TopNav right>
              <HeaderLinkExt
                target="_blank"
                href="https://olssonsfiske.freshdesk.com/support/home"
              >
                <CustomerIcon className="header-icon" />
              </HeaderLinkExt>
              <HeaderLink to="/my-pages">
                <UserIcon className="header-icon" />
              </HeaderLink>
              <CartButton className="header-icon" />
            </TopNav>
          </Inner>
        </PagePadding>
      </MenuBar>
    </MobileHeaderWrapper>
  );
};

export default MobileHeader;
