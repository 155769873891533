import React from 'react';
import SiteContent from '../../Util/SiteContent';
import { styled } from 'linaria/react';
import NewsletterField from '../../Newsletter/NewsletterField';
import { theme } from '../../Theme';
import Image from '@jetshop/ui/Image/Image';

const NewsletterBoxWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  > div {
    width: 100%;
    ${theme.above.lg} {
      width: 50%;
    }
    &.text-wrapper {
      background: ${theme.colors.primary};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 1rem;
      h2,
      h4 {
        color: ${theme.colors.white};
      }
      div {
        > p {
          color: ${theme.colors.white};
          text-align: center;
        }
        button {
          background: ${theme.colors.white};
          color: ${theme.colors.font};
          font-family: ${theme.font.families.heavy};
          text-transform: uppercase;
          font-size: 1.5rem;
          opacity: 1 !important;
        }
      }
    }
  }
`;

const NewsletterBox = () => {
  return (
    <SiteContent names={['NewsletterBox']}>
      {(items, renderLink) => {
        const item = items[0];
        return (
          <>
            {item && (
              <NewsletterBoxWrapper>
                {item.image && (
                  <div>
                    <Image
                      aspect={'2:1'}
                      sizes={[1, 1, 1 / 2, 1 / 2]}
                      src={item.image.url}
                      alt={item.image.url}
                      crop={true}
                      cover
                    />
                  </div>
                )}
                <div className="text-wrapper">
                  {item.content && (
                    <>
                      <h4>{item.content.subTitle}</h4>
                      <h2>{item.content.title}</h2>
                    </>
                  )}
                  <NewsletterField />
                </div>
              </NewsletterBoxWrapper>
            )}
          </>
        );
      }}
    </SiteContent>
  );
};

export default NewsletterBox;
