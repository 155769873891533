import React from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../MaxWidth';
import FooterContent, { FooterContentWrapper } from './FooterContent';
import { theme } from '../../Theme';
import NewsletterBox from './NewsletterBox';

const FooterWrapper = styled('footer')`
  color: ${theme.footer.color};
  background: ${theme.footer.background};
  z-index: 2;
  a {
    text-decoration: none;
    color: ${theme.footer.color};
  }
  .footer-upper-wrapper {
    padding: 50px 0 0;
    a {
    }
  }
  .footer-content {
    padding: 3rem 0;
    border-top: 1px solid ${theme.colors.border};
    ${FooterContentWrapper} {
      padding-top: 0.5rem;
      margin-bottom: 3rem;
      p:first-child {
        display: inline-block;
        color: ${theme.colors.greys[3]};
        ${theme.below.md} {
          display: none;
        }
      }
      .jetshop-text {
        ${theme.above.md} {
          float: right;
        }
        text-align: center;
      }
    }
  }
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <div className="newsletter-box">
        <NewsletterBox />
      </div>
      <div className="footer-content">
        <MaxWidth>
          <FooterContent />
        </MaxWidth>
      </div>
    </FooterWrapper>
  );
};

export default Footer;
