import React, { memo, useState } from 'react';
import { styled } from 'linaria/react';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { StyledCarrot } from '@jetshop/ui/Select/Components';
import { theme } from '../../../../Theme';
import { motion, AnimatePresence } from 'framer-motion';
import t from '@jetshop/intl';

const CategoryWrapper = styled('div')`
  position: relative;
  overflow: hidden;
`;

const CategoryButton = styled('div')`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: inherit;
  text-decoration: none;
  padding: 0 1rem;
  border-bottom: 1px solid ${theme.colors.borderLight};
  background: ${theme.colors.white};
  ${StyledCarrot} {
    margin-right: 0px;
  }
  text-overflow: ellipsis;
  white-space: nowrap;
  &[data-level='1'] {
    font-size: 1.2rem;
    font-family: ${theme.font.families.heavy};
    line-height: 3em;
    border-bottom: 2px solid ${theme.colors.white};
    background: ${theme.colors.greys[1]};
  }
  &[data-level='2'] {
    font-size: 1.1rem;
    line-height: 3em;
  }
  &[data-level='3'] {
    font-size: 1rem;
    line-height: 2em;
    border-left: 20px solid #e0e0e0;
  }
  &.active,
  &.active > a {
    color: ${theme.colors.primary};
  }
  &.selected,
  &.selected > a {
    color: ${theme.colors.primaryLight};
  }
`;

const CategoryHasSubs = styled('span')`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

function inPath(category) {
  let currentPath = '/';
  let returnObject = {
    inPath: false,
    exactPath: false
  };
  if (typeof window !== 'undefined') currentPath = window.location.pathname;
  if (
    category.primaryRoute &&
    currentPath.search(category.primaryRoute.path) !== -1
  ) {
    returnObject.inPath = true;
    returnObject.exactPath = currentPath === category.primaryRoute.path;
  }
  return returnObject;
}

const Category = memo(
  ({
    children,
    category,
    defaultOpen = false,
    level,
    hideTarget,
    activeCategory,
    setActiveCategory
  }) => {
    const hasSubs = category.subcategories && category.subcategories.length > 0;

    let classNames = [];
    const po = inPath(category);

    if (po.inPath) {
      defaultOpen = true;
      classNames.push('active');
    }

    if (po.exactPath || activeCategory === category.id)
      classNames.push('selected');

    const [isOpen, setOpen] = useState(defaultOpen);
    return (
      <CategoryWrapper>
        <CategoryButton data-level={level} className={classNames.join(' ')}>
          {hasSubs ? (
            <CategoryHasSubs onClick={() => setOpen(!isOpen)}>
              {category.name}
              <StyledCarrot open={isOpen} />
            </CategoryHasSubs>
          ) : (
            <CategoryLink
              category={category}
              onClick={() => {
                hideTarget();
                setActiveCategory(category.id);
              }}
            >
              {category.name}
            </CategoryLink>
          )}
        </CategoryButton>
        <AnimatePresence initial={false}>
          {isOpen && (
            <motion.section
              key="content"
              initial="start"
              animate="open"
              exit="collapsed"
              variants={{
                start: { opacity: 0, height: 0 },
                open: { opacity: 1, height: 'auto' },
                collapsed: { opacity: 0.2, height: 0 }
              }}
              transition={{ duration: 0.7, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              <div>
                {hasSubs && (
                  <CategoryButton data-level={level + 1}>
                    <CategoryLink
                      category={category}
                      onClick={() => {
                        hideTarget();
                        setActiveCategory(category.id);
                      }}
                    >
                      {t('Show all')}
                    </CategoryLink>
                  </CategoryButton>
                )}
                {children}
              </div>
            </motion.section>
          )}
        </AnimatePresence>
      </CategoryWrapper>
    );
  }
);

function renderTree(
  parent,
  categories,
  level,
  hideTarget,
  activeCategory,
  setActiveCategory
) {
  if (categories && categories.length > 0) {
    return categories.map(category => (
      <Category
        category={category}
        key={category.id}
        level={level}
        hideTarget={hideTarget}
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
      >
        {renderTree(
          category,
          category.subcategories,
          level + 1,
          hideTarget,
          activeCategory,
          setActiveCategory
        )}
      </Category>
    ));
  } else return null;
}

const MobileCategories = ({ categories, hideTarget }) => {
  const [activeCategory, setActiveCategory] = useState('trail');
  if (categories && categories.length > 0) {
    return (
      <div>
        {renderTree(
          null,
          categories,
          1,
          hideTarget,
          activeCategory,
          setActiveCategory
        )}
      </div>
    );
  } else return null;
};

export default MobileCategories;
