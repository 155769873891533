import React from 'react';
import Image from '@jetshop/ui/Image';

const Logo = ({ mobile = false, size = [146] }) => {
  let src = '/pub_images/original/olssonsfiske_logo.png';
  if (mobile) src = '/pub_images/original/olssonsfiske_logo.png';
  return <Image src={src} sizes={size} aspect={'146:30'} crop={false} />;
};

export default Logo;
