import React, { Fragment } from 'react';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import cartQuery from './CartQuery.gql';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import CartIcon from '../ui/icons/CartIcon';
import { HeaderIconText } from '../Layout/Header/Desktop/DesktopHeader';
import t from '@jetshop/intl';
import { FindifyCartTracker } from '../Findify/Tracking';

const Button = styled('button')`
  display: flex;
  flex-direction: column;
  align-items: center;
  .cart-items-text {
    margin-left: 2px;
  }
`;

/*const CartLabel = styled('span')`
  font-size: 16px;
  color: ${theme.colors.font};
  margin-top: 5px;
  margin-left: 10px;
  text-transform: uppercase;
`;*/

export const CartItems = styled('span')`
  display: block;
  position: absolute;
  right: -10px;
  top: 12px;
  width: 20px;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  background: ${theme.colors.buy};
  color: ${theme.colors.white};
  border-radius: 50%;
  text-align: center;
  ${theme.below.lg} {
    width: 16px;
    height: 16px;
    line-height: 16px;
    font-size: 11px;
    right: -8px;
    top: 16px;
  }
  ${theme.above.lg} {
    display: none;
  }
`;

/*const FlyoutTriggerWrapper = ({ itemsInCart }) => (
  <FlyoutTrigger id="cart-flyout">
    {flyout => (
      <Button onClick={flyout.isOpen ? flyout.hideTarget : flyout.showTarget}>
        <StyledIcon icon={shoppingBag} size={28} />
        {parseInt(itemsInCart, 10) > 0 && <CartItems>{itemsInCart}</CartItems>}
      </Button>
    )}
  </FlyoutTrigger>
);*/

const DrawerTriggerWrapper = ({ itemsInCart, children }) => (
  <DrawerTrigger
    preventOverflow={true}
    id="cart-drawer"
    coverStyles={{ zIndex: 101 }}
  >
    {(drawer) => (
      <Button onClick={drawer.isOpen ? drawer.hideTarget : drawer.showTarget}>
        <CartIcon className="header-icon" />
        {parseInt(itemsInCart, 10) > 0 && <CartItems>{itemsInCart}</CartItems>}
        <HeaderIconText>
          <span>{t('Cart')}</span>
          {parseInt(itemsInCart, 10) > 0 && (
            <span className="cart-items-text">({itemsInCart})</span>
          )}
        </HeaderIconText>
        {children}
      </Button>
    )}
  </DrawerTrigger>
);

const CartButton = ({ children }) => (
  <Fragment>
    <CartProvider query={cartQuery}>
      {(result) => {
        // Set items in cart to the API result.
        // If the result is undefined, fall back to 0
        const itemsInCart = result?.data?.cart?.totalQuantity || 0;
        return (
          <>
            <FindifyCartTracker cartData={result?.data?.cart} />
            <DrawerTriggerWrapper
              itemsInCart={itemsInCart}
              children={children}
            />
          </>
        );
      }}
    </CartProvider>
  </Fragment>
);

export default CartButton;
