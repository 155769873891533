import React, { Component } from 'react';
import SiteContent from '../../Util/SiteContent';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import Logo from '../Header/Logo';

export const FooterContentWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  ${theme.below.md} {
    flex-direction: column;
  }
  > div {
    flex-grow: 1;
    padding: 2rem 2rem;
    ${theme.only.md} {
      width: 100%;
    }
    ${theme.above.lg} {
      width: 23.3333%;
      padding: 3rem 3rem;
    }
    > h3 {
      margin-bottom: 0.5rem;
      line-height: 1.5em;
      font-size: 1.5rem;
      text-align: left;
      text-transform: uppercase;
    }
    li,
    p {
      padding: 0;
      line-height: 1.6em;
    }
    &.logo-wrapper {
      ${theme.above.lg} {
        width: 30%;
        padding: 3rem 1rem;
      }
    }
  }

  .icon-title {
    margin-top: 2rem;
  }
  .icon-list {
    display: flex;
    justify-content: center;
    li {
      padding: 0 0.5rem;
    }
  }
`;

class FooterContent extends Component {
  render() {
    return (
      <SiteContent names={['Footer1', 'Footer2', 'Footer3']}>
        {(items, renderLink) => {
          return (
            <FooterContentWrapper>
              {items.map((item, index) => {
                const { content } = item;
                return (
                  <div key={index}>
                    {content.title && <h3>{renderLink(content.title)}</h3>}
                    {content.list && (
                      <ul>
                        {content.list.map((listItem, index) => (
                          <li key={index}>{renderLink(listItem)}</li>
                        ))}
                      </ul>
                    )}
                  </div>
                );
              })}
              <div className="logo-wrapper">
                <Logo size={[1, 1, 1 / 4, 1 / 4]} />
              </div>
            </FooterContentWrapper>
          );
        }}
      </SiteContent>
    );
  }
}

export default FooterContent;
