import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserHeadset } from '@fortawesome/pro-light-svg-icons';

const CustomerIcon = ({ className = '', ...rest }) => {
  return (
    <FontAwesomeIcon icon={faUserHeadset} {...rest} className={className} />
  );
};

export default CustomerIcon;
