import React from 'react';
import UIButton from '@jetshop/ui/Button';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';
import t from '@jetshop/intl';
import { theme } from '../Theme';

const ButtonStyle = `
  font-family: ${theme.button.fontFamily};
  background-color: ${theme.button.background};
  color: ${theme.button.color};
  height: 54px;
  text-transform: ${theme.button.textTransform};
  font-size:${theme.button.fontSize};
  padding: 0 0.5rem;
  letter-spacing: ${theme.button.letterSpacing};
  ${theme.above.md} {
    max-width: 100%;
  }
  ${theme.below.sm} {
    font-size: 0.9rem;
  }
  svg {
    fill: white;
    height: 1em;
    width: 1em;
    margin-right: 1em;
  }
  > a,
  > a:focus {
    color: ${theme.button.color};
  }
  :active{
    color: ${theme.button.color};
  }
  :disabled {
    background: #f7f7f7;
    border: 1px solid #dedede;
    color: #808080;
    opacity: 0.7;
  }

  &.secondary{
      background-color: white;
      border-color: black;
      color: black;
  };

  &.buy, &.cta{
    background-color: ${theme.colors.buy};
    &:hover {
      background-color: ${theme.colors.buy};
    }
  }
`;

const Button = styled(UIButton)`
  ${ButtonStyle};
`;

export const TrendLink = styled(Link)`
  ${ButtonStyle};
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
`;

export const ButtonLink = styled(Link)`
  ${ButtonStyle};
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
`;

const StyledSpinner = styled(Spinner)`
  height: 25px;
  circle.path {
    stroke: white;
  }
`;

const ButtonWithLoading = ({
  loading,
  loadingText = t('Hold on a moment...'),
  secondary,
  buy,
  cta,
  ...props
}) =>
  loading ? (
    <Button
      {...props}
      className={cx(
        props.className,
        secondary && 'secondary',
        buy && 'buy',
        cta && 'cta'
      )}
    >
      {loadingText}
      <StyledSpinner />
    </Button>
  ) : (
    <Button {...props} />
  );

export default ButtonWithLoading;
