import React, { Component } from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../../MaxWidth';
import SiteContent from '../../../Util/SiteContent';
import { Fragment } from 'react';
import TopNav from '../Util/TopNav';
import TopBarUsp from './TopBarUsp';
import { theme } from '../../../Theme';
import { Notifications } from '../../Notifications';

const TopBarWrapper = styled('div')`
  height: ${theme.topBar.height.lg + 'px'};
  transition: height 0.4s ease;
  background: ${theme.topBar.background};
  ${theme.below.md} {
    height: ${theme.topBar.height.md + 'px'};
  }
  &[data-scrolling='true'] {
    height: ${theme.topBar.height.scroll + 'px'};
    overflow: hidden;
  }
`;

const ContentWrapper = styled('div')`
  > ul {
    display: flex;
    justify-content: center;
    &.top-bar-usp-list {
      width: 100%;
      justify-content: space-between;
      ${theme.below.md} {
        justify-content: center;
        li:nth-of-type(3),
        li:nth-of-type(4) {
          display: none;
        }
      }
    }
    &:not(.top-nav-list) {
      > li {
        padding: 0 0.666rem;
      }
    }
    > li {
      svg {
        color: ${theme.topBar.color};
      }
      > span,
      > a {
        color: ${theme.colors.white};
        line-height: ${theme.topBar.height.lg + 'px'};
        font-size: 12px;
        font-weight: ${theme.font.weights.normal};
        ${theme.below.md} {
          line-height: ${theme.topBar.height.md + 'px'};
        }
      }
    }
  }
  > ul.contact {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

class TopBar extends Component {
  render() {
    const { scrolling } = this.props;
    return (
      <TopBarWrapper data-scrolling={scrolling}>
        <MaxWidth>
          <ContentWrapper>
            <TopBarUsp />
            <SiteContent names={['TopBarLinks']}>
              {(items, renderLink) => {
                const item = items[0];
                return (
                  <Fragment>
                    {item && item.content.list && (
                      <TopNav right>
                        {item.content.list &&
                          item.content.list.map((listItem, index) => (
                            <Fragment key={index}>
                              {renderLink(listItem)}
                            </Fragment>
                          ))}
                      </TopNav>
                    )}
                  </Fragment>
                );
              }}
            </SiteContent>
          </ContentWrapper>
        </MaxWidth>
        <Notifications />
      </TopBarWrapper>
    );
  }
}

export default TopBar;
