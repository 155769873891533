import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { addToCartSuccess } from '@jetshop/core/components/Mutation/AddToCart/addToCartUtils';
import { CartIdContext } from '@jetshop/core/components/Cart/CartIdContext';
import { addToCart as addToCartQuery } from '../Cart/queries/addToCart.gql';
import { useHistory } from 'react-router';
import { useNotification } from '@jetshop/core/components/Notifications';
import { AddToCartTracker } from './Tracking';
import ProductToastWrapper from '../ProductPage/AddToCart/ProductToast';

// THIS FILE IS FOR EXPOSING FLIGHT FUNCTIONS AND STATE TO FINDIFY.
// NORMALLY YOU SHOULD NOT NEED TO EDIT THIS FILE

export const useFlightFunctions = () => {
  useEffect(() => {
    init();
    return cleanUp;
  });

  // ADD TO CART
  const { cartId, setCartId } = useContext(CartIdContext);
  const [trigger] = useNotification();
  const [articleNumber, setArticleNumber] = useState('');

  const onAddToCartSuccess = (callbackOptions) => {
    const cart = callbackOptions.cart;
    const addedItem = getProductFromCartItems(cart.items);
    const price = {
      price: addedItem.variant?.price || addedItem.product.price,
      previousPrice:
        addedItem.variant?.previousPrice || addedItem.product.previousPrice,
    };

    trigger(
      <>
        <AddToCartTracker
          cartItem={addedItem}
          eventId={callbackOptions.eventId}
        />
        <ProductToastWrapper
          selectedVariation={addedItem.variant}
          product={addedItem.product}
          quantity={1}
          price={price}
          cart={cart}
        />
      </>
    );
  };

  const getProductFromCartItems = (cartItems) => {
    const cartItem = cartItems.find(
      (item) => item.articleNumber === articleNumber
    );
    return cartItem;
  };

  const [add] = useMutation(addToCartQuery, {
    onCompleted: (data) => {
      const callbackOptions = {
        cart: data.addToCart.cart,
        eventId: Date.now().toString(),
      };
      addToCartSuccess({
        onAddToCartSuccess,
        callbackOptions,
        cartId,
        setCartId,
      })({ data });
    },
  });

  const addToCart = (articleNumber) => {
    setArticleNumber(articleNumber);
    add({
      variables: {
        input: {
          cartId,
          articleNumber: articleNumber,
          quantity: 1,
        },
      },
    });
  };

  // FINDIFY INTERNAL FLIGHT LINKING
  const history = useHistory();
  const goToRoute = (route) => {
    // FOOLPROOF FIX FOR BAD URLS FROM HYPERDRIVE
    if (route.match('https')) {
      console.warn('Absolute url detected: ', route);
      route = route.replace('https://', '');
      route = route.substr(route.indexOf('/'));
    }
    history.push(route);
  };

  // EXPOSE METHODS FOR FINDIFY
  const init = () => {
    window.flightFunctions = {};
    window.flightFunctions.addToCart = addToCart;
    window.flightFunctions.toggleFavourite = () => {};
    window.flightFunctions.isFavourite = () => {};
    window.flightFunctions.goToRoute = goToRoute;
  };

  const cleanUp = () => {
    window.flightFunctions = null;
  };
};
