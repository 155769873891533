import React from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import CartButton from '../../../Cart/CartButton';
import { Inner, PagePadding, PagePaddingWrapper } from '../../MaxWidth';
import TopNav from '../Util/TopNav';
import t from '@jetshop/intl';
import Logo from '../Logo';
import CustomerIcon from '../../../ui/icons/CustomerIcon';
import UserIcon from '../../../ui/icons/UserIcon';
import { theme } from '../../../Theme';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import SearchBar from '../../../Findify/Search/SearchBar';

const DesktopHeaderWrapper = styled('div')`
  height: ${theme.desktopHeader.height.lg + 'px'};
  transition: height 0.6s ease;
  padding: 0 0;
  ${theme.only.md} {
    height: ${theme.desktopHeader.height.md + 'px'};
  }
  &[data-scrolling='true'] {
    height: ${theme.desktopHeader.height.scroll + 'px'};
  }
  ${PagePaddingWrapper},
  ${Inner} {
    height: 100%;
  }
  ${Inner} {
    display: flex;
    align-items: center;
  }

  .top-nav-list {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    > li {
      padding: 0 1rem;
      display: flex;
      height: 100%;
      align-items: center;
      &:last-child {
        padding-right: 1.2rem !important;
      }
    }
  }
  .header-icon {
    font-size: 22px;
  }
`;

const StoreLogo = styled('div')`
  max-height: ${theme.desktopHeader.height.lg + 'px'};
  transition: height 0.4s ease;
  width: 146px;
  transition: width 0.4s ease;
  //margin: 0 auto;
  float: left;
  ${theme.only.md} {
    height: ${theme.desktopHeader.height.md + 'px'};
  }
  text-align: center;
  text-decoration: none;
  color: inherit;
  a {
    text-decoration: none;
    color: inherit;
    > img,
    > div,
    > svg {
      transition: max-height 0.4s ease;
      max-width: 100%;
    }
  }
  &[data-scrolling='true'] {
    max-height: ${theme.desktopHeader.height.scroll + 'px'};
    a {
      > img,
      > div,
      > svg,
      > .scroll-logo {
      }
    }
    .scroll-logo {
    }
  }
`;

export const HeaderButton = styled('button')`
  display: flex;
`;

export const HeaderIconText = styled('span')`
  display: block;
  ${theme.below.lg} {
    display: none;
  }
`;

const HeaderLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SearchFieldWrapper = styled('div')`
  border: 2px solid ${theme.colors.primaryLight};
  border-radius: 10px;
  position: absolute;
  width: 35%;
  left: 50%;
  transform: translateX(-50%) translateY(0%);
  > div {
    padding-right: 10px;
    width: 100%;
  }
  input {
    border-radius: 10px;
    border: none;
    height: 42px;
  }
  .submit-search {
    right: 1rem;
  }
  .close-search {
    display: none;
  }
`;

const DesktopHeader = ({
  scrolling,
  searchOpen,
  setSearch,
  categoryTreeRoots,
}) => {
  const { loggedIn } = useAuth();
  return (
    <DesktopHeaderWrapper data-scrolling={scrolling}>
      <PagePadding>
        <Inner>
          <StoreLogo data-scrolling={scrolling}>
            <Link to="/">
              <Logo type="desktop" />
            </Link>
          </StoreLogo>
          <SearchFieldWrapper>
            <SearchBar />
          </SearchFieldWrapper>
          <TopNav right>
            <HeaderLink to="/kundservice">
              {/* <HeaderLinkExt
              target="_blank"
              href="https://olssonsfiske.freshdesk.com/support/home"
            > */}
              <CustomerIcon className="header-icon" />
              <HeaderIconText>{t('Service')}</HeaderIconText>
            </HeaderLink>
            <HeaderLink to="/my-pages">
              <UserIcon className="header-icon" />
              <HeaderIconText>
                {loggedIn ? t('My Pages') : t('Login')}
              </HeaderIconText>
            </HeaderLink>
            <CartButton />
          </TopNav>
        </Inner>
      </PagePadding>
    </DesktopHeaderWrapper>
  );
};

export default DesktopHeader;
