import React from 'react';
import { styled } from 'linaria/react';
import useFindify from '@findify/react-bundle';
import { getBaseItemId } from './FindifyProvider';
import Spinner from '../ui/Spinner';

const FindifyRecommendationWrapper = styled('div')`
  padding: 1rem 0;
  width: 100%;

  .spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

const FindifyRecommendation = ({ id, product, rules = false }) => {
  const recId = typeof id === 'string' ? id.trim() : null;

  let dataObj = {
    type: 'recommendation',
    config: {
      slot: recId, // EXAMPLE: 'product-findify-rec-12'
    },
    options: {},
  };

  // ON PRODUCT PAGE - INCLUDE PRODUCT DATA
  const articleNumber = getBaseItemId(product?.articleNumber);
  if (articleNumber) {
    dataObj.options = {
      item_ids: [articleNumber],
    };
  }

  if (rules) {
    dataObj.options.rules = rules;
  }

  const [container, isReady, hasError] = useFindify(dataObj);

  if (!recId) {
    console.error('Findify Recommendation widget needs id argument');
    return null;
  }

  if (hasError) {
    console.error(
      'Error fetching Findify Recommendation widget with id ' + recId
    );
    return null;
  }

  return (
    <FindifyRecommendationWrapper>
      <div ref={container} />
      {!isReady && (
        <div className={'spinner-container'}>
          <Spinner />
        </div>
      )}
    </FindifyRecommendationWrapper>
  );
};

export default FindifyRecommendation;
